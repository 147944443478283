.plugins-container {
  background-color: #f8f8f8;
}

.plugin-container {
  background-color: white;
  padding: 24px;
  margin: 0 12px 24px 12px;
  display: flex;
  flex-direction: column;
}

.plugin-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.plugin-header > span {
  color: var(--primary-text-color);
  font-size: 18px;
  font-weight: 500;
  margin-left: 16px;
  font-family: 'Exo';
}

.plugin-desc {
  margin: 16px 0 24px 0;
  text-align: left;
  color: #95989a;
  font-size: 14px;
  min-height: 50px;
  font-family: 'Exo';
}
