body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Exo, Kongsho MG Regular,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-bg-color: #8ec640;
  --primary-layout-color: #f9f9f9;
  --primary-text-color: #2d2d2d;
  /* --primary-bg-color: #6f2c91; */
  /* --primary-bg-color: #faa61b; */
}

@font-face {
  font-family: 'Kongsho MG Regular';
  src: url('/public/KongshoMJ_Regular.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* body,html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
} */
