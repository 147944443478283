.domain-home-continer {
  padding: 24px;
}

.domain-home-header {
  background: #f8f8f8;
  padding: 20px 40px;
  color: var(--primary-text-color);
}

.domain-home-data {
  padding: 20px 40px;
  color: var(--primary-text-color);
}

.domain-home-chip {
  background-color: #00dd641a;
  font-size: 12px;
  padding: 4px 6px;
  color: #00dd64;
  border-radius: 8px;
}

.domain-home-btn-container {
  background-color: #f8f8f8;
  padding: 24px 0;
}

.domain-add-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.domain-add-title {
  color: var(--primary-text-color);
  font-size: 22px;
  margin: 0;
}

.domain-add-details-title {
  background-color: #f8f8f8;
  color: #95989a;
  text-align: left;
  font-size: 16px;
  padding: 24px 0;
}
