.cl-ul {
  margin: 0 16px;
  background-color: white;
  padding: 0;
}

.cl-li {
  list-style-type: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.cl-li:nth-child(-n + 5) {
  border-bottom: 1px solid #d1d1d1;
}

.cl-li > img {
  height: 24px;
  width: 24px;
  padding: 16px 18px;
}

.cl-li > span {
  color: #95989a;
}

.main {
  margin: 0 16px;
}

.selected > span {
  color: var(--primary-text-color);
  font-weight: 500;
}
